import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import get from 'lodash/get';
import Layout from '../components/layout';
import BackgroundImage from 'gatsby-background-image';

class Imprint extends React.Component {
  render() {
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');
    const page = get(this, 'props.data.contentfulSeite');

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <SEO title={page.title} />
        <BackgroundImage backgroundColor="#0e3920" className="header" fluid={page.heroImage.fluid}>
          <h1>{page.title}</h1>
        </BackgroundImage>
        <div className="container">
          {page.text &&
            <article  
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: page.text.childMarkdownRemark.html,
              }} 
            />
          }
        </div>
      </Layout>
    )
  }
}

export default Imprint;

export const pageQuery = graphql`
  query ImprintQuery {
    contentfulSeite(slug: { eq: "impressum" }) {
      title
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
